import { createSlice } from "@reduxjs/toolkit";

import { AuthState } from "../../interfaces";
import { authApi } from "./auth.api";

const initialState: AuthState = {
  loggedIn: false,
  token: "",
  user: {
    id: "",
    name: "",
    email: "",
    registration_number: "",
    mobile_number: "",
    active: true,
    batches: [],
  },
};

export const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, { payload }) => {
      state.token = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
      state.user = payload.user;
      state.user.name = payload.user.name;
      state.token = payload.token;
      state.loggedIn = true;
    });

    // builder.addMatcher(authApi.endpoints.register.matchFulfilled, (state, { payload }) => {
    //   state.user = payload.user;
    //   state.user.name = payload.user.name;
    //   state.token = payload.token;
    //   state.loggedIn = true;
    // });

    builder.addMatcher(authApi.endpoints.logout.matchPending, () => {
      return initialState;
    });
    builder.addMatcher(authApi.endpoints.authenticate.matchRejected, () => {
      return initialState;
    });
    builder.addMatcher(authApi.endpoints.authenticate.matchFulfilled, (state, { payload }) => {
      state.user = {
        ...state.user,
        ...payload.user,
      };
    });
  },
});

export const { setToken } = slice.actions;

export const authReducer = slice.reducer;
