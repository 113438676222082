import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { API_ENDPOINT } from "../../config";
import { ActivityLog, CreateRequestsDto, Request, RequestState } from "../../interfaces";

export const requestApi = createApi({
  reducerPath: "requestApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT + "/request",
    credentials: "include",
  }),
  endpoints: (build) => ({
    getRequests: build.query<RequestState, {}>({
      query: (params) => ({
        url: "/",
        method: "GET",
        params,
      }),
    }),
    createRequest: build.mutation<Request, CreateRequestsDto>({
      query: (payload) => ({
        url: "/",
        method: "POST",
        body: payload,
      }),
    }),
    getSingleRequeust: build.query<{ request: Request; activity: ActivityLog[] }, {}>({
      query: (id) => ({
        url: `/${id}`,
        method: "GET",
      }),
    }),
    updateRequest: build.mutation<Request, Partial<Request>>({
      query: ({ id, ...payload }) => ({
        url: `/${id}`,
        method: "PUT",
        body: payload,
      }),
    }),
  }),
});

export const { useGetRequestsQuery, useCreateRequestMutation, useGetSingleRequeustQuery, useUpdateRequestMutation } =
  requestApi;
