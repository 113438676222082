import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { API_ENDPOINT } from "../../config";
import { GetBatchClassDto, PerformanceResponse } from "../../interfaces";

export const performanceApi = createApi({
  reducerPath: "performanceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT + "/performance",
    credentials: "include",
  }),
  endpoints: (build) => ({
    getPerformances: build.query<PerformanceResponse, GetBatchClassDto>({
      query: (params) => ({
        url: "/student",
        method: "GET",
        params,
      }),
    }),
  }),
});

export const { useGetPerformancesQuery } = performanceApi;
