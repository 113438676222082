import { Navigate, Outlet } from "react-router-dom";

import { useAppSelector } from "../hooks";
import { RootState } from "../redux/store";
import { useAuthenticateQuery } from "../redux/auth/auth.api";

export const PrivateRoute = () => {
  useAuthenticateQuery();
  const isAuthenticated = useAppSelector((state: RootState) => !!state?.auth.loggedIn);

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};
