import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { API_ENDPOINT } from "../../config";
import { Announcement, GetAnnouncementDto, GetAnnouncementResponse } from "../../interfaces";

export const announcementApi = createApi({
  reducerPath: "announcementApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT + "/announcement",
    credentials: "include",
  }),
  endpoints: (build) => ({
    getAnnouncements: build.query<GetAnnouncementResponse, Partial<GetAnnouncementDto>>({
      query: (params) => ({
        url: "/student",
        method: "GET",
        params,
      }),
    }),

    getAnnouncement: build.query<Announcement, string>({
      query: (id) => ({
        url: "/" + id,
        method: "GET",
      }),
    }),
    seenAnnouncement: build.mutation<Announcement, string>({
      query: (id) => ({
        url: "/seen/" + id,
        method: "PUT",
      }),
    }),
  }),
});

export const { useGetAnnouncementsQuery, useGetAnnouncementQuery, useSeenAnnouncementMutation } = announcementApi;
