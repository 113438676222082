import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { API_ENDPOINT } from '../../config';
import { CategoryResponse } from '../../interfaces';

export const categoryApi = createApi({
  reducerPath: 'categoryApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT + '/category',
    credentials: 'include',
  }),
  endpoints: (build) => ({
    getCategores: build.query<CategoryResponse, {}>({
      query: (params) => ({
        url: '/',
        method: 'GET',
        params,
      }),
    }),
  }),
});

export const { useGetCategoresQuery } = categoryApi;
