import React from "react";
import ReactSelect from "react-select";

interface ISelect {
  id: string;
  className?: string;
  label: string;
  options: {
    name: string;
    value: string;
  }[];
  readonly?: boolean;
  value: string | string[];
  onChange: Function;
  textColor?: string;
  multiple?: boolean;
  disabled?: boolean;
}

export function Select({
  id,
  className = "",
  label,
  options,
  value,
  onChange,
  readonly = false,
  textColor = "text-dark-blue",
  multiple = false,
  disabled = false,
}: ISelect) {
  return (
    <div className={className}>
      <label className="text-gray-500 flex-shrink-0 md:text-sm text-xs" htmlFor={id}>
        {label}
      </label>
      {!readonly ? (
        <select
          multiple={multiple}
          id={id}
          className="w-full rounded-md border bg-white"
          value={value}
          onChange={(e) => {
            onChange(e);
          }}
          disabled={disabled}
        >
          {options.map((e, i) => {
            return (
              <option key={i} value={e.value}>
                {e.name}
              </option>
            );
          })}
        </select>
      ) : (
        <p className={"md:text-lg text-sm " + textColor}>{value}</p>
      )}
    </div>
  );
}

interface ISelectPlusOption {
  label: any;
  value: string;
}

interface ISelectPlus {
  id: string;
  className?: string;
  label: string;
  options: ISelectPlusOption[];
  readonly?: boolean;
  value: ISelectPlusOption | ISelectPlusOption[];
  onChange: Function;
  textColor?: string;
  multiple?: boolean;
  disabled?: boolean;
}

export function SelectPlus({
  id,
  className = "",
  label,
  options,
  value,
  onChange,
  readonly = false,
  textColor = "text-dark-blue",
  multiple = false,
  disabled = false,
}: ISelectPlus) {
  return (
    <div className={className}>
      <label className="text-gray-500  text-sm flex-shrink-0" htmlFor={id}>
        {label}
      </label>
      {!readonly ? (
        <ReactSelect
          options={options}
          isMulti={multiple}
          id={id}
          value={value}
          onChange={(e) => {
            onChange(e);
          }}
          isDisabled={disabled}
        />
      ) : (
        <p className={"text-lg " + textColor}>{JSON.stringify(value)}</p>
      )}
    </div>
  );
}
