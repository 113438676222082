import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { API_ENDPOINT } from "../../config";
import { toastMessage } from "../../utils/toastError";
import { ContentsResponse, GetContentsDto } from "../../interfaces";

export const contentApi = createApi({
  reducerPath: "contentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT + "/content",
    credentials: "include",
  }),
  endpoints: (build) => ({
    getContents: build.query<ContentsResponse, Partial<GetContentsDto>>({
      query: (params) => ({
        url: "/",
        method: "GET",
        params,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
      },
    }),
    getEmbedContent: build.query<any, { url: string }>({
      query: (params) => ({
        url: "/embed",
        method: "GET",
        params,
      }),
    
    }),
  }),
});

export const { useGetContentsQuery, useLazyGetEmbedContentQuery } = contentApi;
