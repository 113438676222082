import React from 'react';

interface ITextArea {
  id: string;
  className?: string;
  placeholder?: string;
  label?: string;
  value?: string;
  onChange?: Function;
  readonly?: boolean;
  textColor?: string;
  rows?: number;
}

export function TextArea({
  id,
  className = '',
  placeholder,
  label,
  value,
  onChange,
  readonly = false,
  textColor = 'text-dark-blue',
  rows,
}: ITextArea) {
  return (
    <div className={'flex flex-col items-start justify-center ' + className}>
      <label className="text-gray-500 text-sm" htmlFor={id}>
        {label}
      </label>
      {!readonly ? (
        <textarea
          id={id}
          className="w-full rounded-md p-2 border"
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            if (onChange) onChange(e);
          }}
          rows={rows}
        />
      ) : (
        <p className={'text-lg ' + textColor}>{value}</p>
      )}
    </div>
  );
}
