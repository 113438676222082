import React, { ButtonHTMLAttributes, ReactNode } from "react";
import Loader from "react-loader-spinner";
import { twMerge } from "tailwind-merge";

interface IButton extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "onClick"> {
  text: string | ReactNode;
  size?: string;
  bg?: string;
  textColor?: string;
  loading?: boolean;
  onClick?: Function;
}

export function Button({
  text,
  bg = "",
  size,
  className,
  loading = false,
  textColor = "text-gray-200",
  onClick,
  ...props
}: IButton) {
  return (
    <button
      onClick={() => {
        if (loading) return;
        if (onClick) onClick();
      }}
      className={twMerge("p-2 rounded-md transition ", bg, size, textColor, className)}
      {...props}
    >
      {loading ? (
        <div className="flex gap-3 items-center justify-center">
          <Loader type="Oval" color="#ffffff" height={20} width={20} /> {text}
        </div>
      ) : (
        text
      )}
    </button>
  );
}
