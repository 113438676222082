interface IInput {
  id: string;
  className?: string;
  placeholder?: string;
  type?: string;
  label?: string;
  value?: any;
  onChange?: Function;
  readonly?: boolean;
  textColor?: string;
  disabled?: boolean;
}

export function Input({
  id,
  className = '',
  placeholder,
  type = 'text',
  label,
  value,
  onChange,
  readonly = false,
  textColor = 'text-dark-blue',
  disabled = false,
}: IInput) {
  return (
    <div className={'flex flex-col items-start justify-center ' + className}>
      <label className="text-gray-500 md:text-sm text-xs " htmlFor={id}>
        {label}
      </label>
      {!readonly ? (
        <input
          id={id}
          className="w-full rounded-md p-2 border"
          placeholder={placeholder}
          type={type}
          value={value}
          onChange={(e) => {
            typeof onChange === 'function' && onChange(e);
          }}
          disabled={disabled}
        />
      ) : (
        <p className={'md:text-lg text-sm ' + textColor}>{value || '-'}</p>
      )}
    </div>
  );
}
