import React from "react";

import { RequestStatus } from "../interfaces";
import { getRequestStatus } from "../lib";

import { StatusTag } from "./StatusTag";
import { twMerge } from "tailwind-merge";

interface IRequestStatusTag {
  status: string;
  rounded?: boolean;
  selected?: boolean;
  // eslint-disable-next-line no-unused-vars
  onClick?: (event?: MouseEvent) => void;
  count?: number;
  className?: string;
}

export const RequestStatusTag = ({
  status,
  rounded,
  onClick,
  selected,
  count,
  className: _className = "",
}: IRequestStatusTag) => {
  let className = "";
  let type = "";
  if (status === RequestStatus.OPEN) {
    type = "warning";
    className += ` ${selected ? "bg-orange-500 border-orange-500 text-white" : "border-orange-500 text-orange-500"} `;
  } else if (status === RequestStatus.RESOLVED) {
    type = "success";
    className += ` ${selected ? "bg-green-500 border-green-500 text-white" : "border-green-500 text-green-500"} `;
  } else if (status === RequestStatus.CLOSED) {
    type = "error";
    className += ` ${selected ? "bg-red-500 border-red-500  text-white" : "border-red-500 text-red-500"} `;
  } else if (status === RequestStatus.PROCESSING) {
    type = "info";
    className += ` ${selected ? "bg-cyan-500 border-cyan-500 text-white" : "border-cyan-500 text-cyan-500"} `;
  }

  if (rounded) {
    className += " rounded-full ";
  }

  return (
    <StatusTag
      className={twMerge(className, _className)}
      type={type}
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      {getRequestStatus(status)} {count !== undefined && <span>({count})</span>}
    </StatusTag>
  );
};
