import { useLocation, useSearchParams } from "react-router-dom";
import { useAppSelector } from ".";

export const useSearchQuery = (name: string) => {
  const location = useLocation();
  const [query] = useSearchParams(location.search);

  return query.get(name);
};

export const useUserInfo = () => {
  const user = useAppSelector((state) => state.auth.user);

  return user;
};
