import { Action, combineReducers, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import { categoryApi } from "./category/category.api";
import { requestApi } from "./request/request.api";
import { studentApi } from "./student/student.api";
import { authApi } from "./auth/auth.api";
import { authReducer } from "./auth/auth.slice";
import { announcementApi } from "./announcement/annoucement.api";
import { contentApi } from "./content/content.api";
import { attendanceApi } from "./attendance/attendance.api";
import { performanceApi } from "./perforomance/performance.api";
import { testApi } from "./test/test.api";
import { batchApi } from "./batch/batch.api";

const persistConfig = {
  key: "CHESTA_AUTH",
  storage,
};

const rootReducer = combineReducers({
  auth: persistReducer(persistConfig, authReducer),
  [authApi.reducerPath]: authApi.reducer,
  [requestApi.reducerPath]: requestApi.reducer,
  [categoryApi.reducerPath]: categoryApi.reducer,
  [studentApi.reducerPath]: studentApi.reducer,
  [announcementApi.reducerPath]: announcementApi.reducer,
  [contentApi.reducerPath]: contentApi.reducer,
  [attendanceApi.reducerPath]: attendanceApi.reducer,
  [performanceApi.reducerPath]: performanceApi.reducer,
  [testApi.reducerPath]: testApi.reducer,
  [batchApi.reducerPath]: batchApi.reducer,
});

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(authApi.middleware)
      .concat(requestApi.middleware)
      .concat(categoryApi.middleware)
      .concat(announcementApi.middleware)
      .concat(studentApi.middleware)
      .concat(contentApi.middleware)
      .concat(attendanceApi.middleware)
      .concat(performanceApi.middleware)
      .concat(testApi.middleware)
      .concat(batchApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export const persistor = persistStore(store);
export default store;
