import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { API_ENDPOINT } from "../../config";
import { Batch, Course, Payment } from "../../interfaces";

export const batchApi = createApi({
  reducerPath: "batchApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT + "/batch",
    credentials: "include",
  }),
  endpoints: (build) => ({
    getBatch: build.query<any, {}>({
      query: (id) => ({
        url: "/" + id,
        method: "GET",
      }),
    }),
    getCourses: build.query<{ entries: Course[]; total: number }, void>({
      query: () => ({
        url: "/course",
        method: "GET",
      }),
    }),
    payCourse: build.mutation<{ order: any; payment: Payment; apiKey: string }, { course_id: string }>({
      query: (body) => ({
        url: "/course/pay",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useGetBatchQuery, useGetCoursesQuery, usePayCourseMutation } = batchApi;
