import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { API_ENDPOINT } from "../../config";
import {
  Leaderboard,
  LeaderboardResponse,
  StudentMark,
  StudentTest,
  TestAnswerDto,
  TestResponse,
} from "../../interfaces";
import { toastMessage } from "../../utils/toastError";

export const testApi = createApi({
  reducerPath: "testApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT + "/test/student",
    credentials: "include",
  }),
  endpoints: (build) => ({
    getTests: build.query<TestResponse, any>({
      query: (params) => ({
        url: "/",
        method: "GET",
        params,
      }),
    }),
    getTest: build.query<any, any>({
      query: (id) => ({
        url: "/" + id,
        method: "GET",
      }),
    }),
    getStudentTests: build.query<StudentTest, { id: string; type: "test" | "result" | "solution" }>({
      query: (params) => ({
        url: "/student-test",
        method: "GET",
        params,
      }),
    }),
    getTestLeaderboard: build.query<LeaderboardResponse, { test_id: string; batch_id: string }>({
      query: (params) => ({
        url: "/leaderboard",
        method: "GET",
        params,
      }),
    }),
    testStart: build.mutation<StudentTest, { batch_id: string; test_id: string }>({
      query: (payload) => ({
        url: "/start-test",
        method: "POST",
        body: payload,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
        return Promise.reject(baseQueryReturnValue.data);
      },
    }),
    testComplete: build.mutation<Request, string>({
      query: (id) => ({
        url: "/complete-test/" + id,
        method: "POST",
      }),
    }),
    testQuestionAnswer: build.mutation<Request, TestAnswerDto>({
      query: (payload) => ({
        url: "/question-answer",
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetTestsQuery,
  useGetTestQuery,
  useGetStudentTestsQuery,
  useTestStartMutation,
  useTestQuestionAnswerMutation,
  useTestCompleteMutation,
  useLazyGetTestLeaderboardQuery,
} = testApi;
