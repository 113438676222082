import { ChangeEvent } from 'react';
import { AiFillCloseCircle } from 'react-icons/ai';

import { Input } from './Input';
import { Select } from './Select';

interface ISKUItem {
  trackingId?: string;
  setTrackingId?: Function;
  dateOfDispatch?: string;
  setDateOfDispatch?: Function;
  deliveryPartner?: string;
  setDeliveryPartner?: Function;
  skuCode?: string;
  setSkuCode?: Function;
  quantity?: number;
  setQuantity?: Function;
  date?: string;
  setDate?: Function;
  deleteItem?: Function;
  skuCodes?: string[];
  readonly?: boolean;
}

export function SKUItem({
  trackingId,
  setTrackingId = () => {},
  dateOfDispatch,
  setDateOfDispatch = () => {},
  deliveryPartner,
  setDeliveryPartner = () => {},
  skuCode = '',
  setSkuCode = () => {},
  quantity,
  setQuantity = () => {},
  date,
  setDate = () => {},
  deleteItem = () => {},
  skuCodes = [],
  readonly = false,
}: ISKUItem) {
  return (
    <div className="p-2 bg-gray-300 rounded-md mt-2 relative">
      {!readonly && (
        <AiFillCloseCircle
          className="absolute top-2 right-2 hover:text-red-500 cursor-pointer"
          onClick={() => {
            deleteItem();
          }}
        />
      )}
      <div className="grid grid-cols-2 gap-2">
        {trackingId !== undefined && (
          <Input
            id="payment-sku-tracking-id"
            type="text"
            className="w-full mt-2 mr-4"
            placeholder="Enter tracking ID"
            label="Tracking ID"
            value={trackingId}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setTrackingId(e.target.value);
            }}
            readonly={readonly}
          />
        )}
        {dateOfDispatch !== undefined && (
          <Input
            id="payment-sku-date-of-dispatch"
            type="date"
            className="w-full mt-2"
            placeholder="Enter tracking ID"
            label="Date of Dispatch"
            value={dateOfDispatch}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setDateOfDispatch(e.target.value);
            }}
            readonly={readonly}
          />
        )}
        {deliveryPartner !== undefined && (
          <Input
            id="payment-sku-tracking-id"
            type="text"
            className="w-full mt-2 mr-4"
            placeholder="Enter delivery partner"
            label="Delivery Partner"
            value={deliveryPartner}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setDeliveryPartner(e.target.value);
            }}
            readonly={readonly}
          />
        )}
        {skuCode !== undefined && (
          <Select
            id="payment-sku-date-of-dispatch"
            className="w-full mt-2"
            options={[{ name: 'Select SKU Code', value: '' }, ...skuCodes?.map((e) => ({ name: e, value: e }))]}
            label="SKU Code"
            value={skuCode}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setSkuCode(e.target.value);
            }}
            readonly={readonly}
          />
        )}
        {quantity !== undefined && (
          <Input
            id="payment-sku-tracking-id"
            type="number"
            className="w-full mt-2 mr-4"
            placeholder="Enter quantity"
            label="Quantity"
            value={quantity + ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setQuantity(+e.target.value);
            }}
            readonly={readonly}
          />
        )}

        {date !== undefined && (
          <Input
            id="payment-sku-date"
            type="date"
            className="w-full mt-2"
            placeholder="Enter tracking ID"
            label="Date"
            value={date}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setDate(e.target.value);
            }}
            readonly={readonly}
          />
        )}
      </div>
    </div>
  );
}
