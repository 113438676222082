import React from "react";
import { AiOutlineBars, AiOutlineDollarCircle, AiOutlinePullRequest } from "react-icons/ai";
import { NavLink } from "react-router-dom";

export interface ISidebar {
  label: string;
  path: string;
  icon: React.ReactNode;
  exact: boolean;
}

const studentSidebar: ISidebar[] = [
  {
    label: "Dashboard",
    path: "/dashboard",
    icon: <AiOutlineBars />,
    exact: true,
  },
  {
    label: "Payments",
    path: "/payments",
    icon: <AiOutlineDollarCircle />,
    exact: true,
  },
  {
    label: "Requests",
    path: "/requests",
    icon: <AiOutlinePullRequest />,
    exact: true,
  },
];

export function SidebarMenu() {
  const sidebar = studentSidebar;
  return (
    <ul className="mt-4">
      {sidebar.map((opt) => (
        <NavLink
          key={opt.path}
          to={opt.path}
          // exact={opt.exact}
          className={"flex p-3 w-full justify-between rounded-md cursor-pointer items-center"}
          // activeClassName="bg-gray-600 text-gray-100"
        >
          <div className="flex items-center">
            {opt.icon}
            <span className="text-sm  ml-2">{opt.label}</span>
          </div>
        </NavLink>
      ))}
    </ul>
  );
}
