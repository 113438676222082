/* eslint-disable no-unused-vars */
export enum PaymentPurpose {
  ADMISSION_FEE = "Admission Fee",
  MODULE_FEE = "Module Fee",
  POSTAL_FEE = "Postal Fee",
  OTHERS = "Others",
}

export interface ActionType {
  type: string;
  payload?: any;
}

export interface RequestError {
  data: {
    error: any;
    message: string;
    statusCode: number;
  };
  status: number;
}

export interface DefaultResponse {
  success: boolean;
  message: string;
}

export interface Student {
  id: string;
  name?: string;
  email: string;
  registration_number: string;
  mobile_number: string;
  active: boolean;
  address?: string;
  permanent_address?: string;
  fathers_name?: string;
  dob?: string;
  qualification?: string;
  gender?: string;
  category?: Category;
  category_id?: string;
  employment?: string;
  study_center?: string;
  batches?: StudentBatch[];
  password?: string;
  change_password?: boolean;
  last_token?: string;
  last_refresh_token?: string;
  forget_password_token?: string;
  admin_note?: string;
  payments?: Payment[];
  time?: string;
}

export interface Payment {
  batch_id?: string;
  id: string;
  payment_request_id: string;
  payment_id: string;
  purpose: PaymentPurpose;
  description: string;
  buyer_name: string;
  buyer_email: string;
  buyer_mobile: string;
  status: string;
  long_url: string;
  amount: number;
  fees: number;
  due_date?: string;
  payment_date?: string;
  student_id: string;
  student: Student;
  payment_notes: string;
  time: string;
  sku_items: SKUItem[];
  batch?: Batch;
  payment_method: string;
  fulfilment: Boolean;
}

export interface StudentBatch {
  id: string;
  student: Student;
  batch: Batch;
  student_id: string;
  batch_id: string;
  active: boolean;
}

export interface BatchExtend extends Batch {
  enrolled_students: number;
  active_students: number;
  inactive_students: number;
}

export interface AllAmounts {
  admissionFeePaid: number;
  moduleFeePaid: number;
  postalFeePaid: number;
  othersFeePaid: number;
  admissionFeeDue: number;
  moduleFeeDue: number;
  postalFeeDue: number;
  othersFeeDue: number;
}

export interface Member {
  id: string;
  name: string;
  role: string;
  email: string;
  type: string;
  active: boolean;
  employee_code?: string;
  mobile_number?: string;
  mobile_number_alt?: string;
  present_address?: string;
  permanent_address?: string;
  fathers_name?: string;
  dob?: string;
  qualification?: string;
  employment?: string;
  gender?: string;
  profile_picture?: File;
}
export interface TeacherAssignee {
  id: string;
  teacher?: Member;
  batch?: Batch;
  teacher_id: string;
  batch_id: string;
  active: boolean;
}

export interface Batch {
  id: string;
  name: string;
  course_code: string;
  start_date: string;
  admin_note: string;
  category_id: string;
  category: Category;
  students: StudentBatch[];
  teachers: TeacherAssignee[];
  price: number;
  type: string;
}

export interface Course {
  id: string;
  batch?: Batch;
  batch_id: string;
  course_fee: number;
  description: string;
  created_at: string;
  updated_at: string;
}

export interface Category {
  id: string;
  name: string;
  description?: string;
  type: string;
  created_at?: string;
  updated_at?: string;
  batches?: Batch[];
}

export interface Inventory {
  id: string;
  name: string;
  description: string;
  sku_code: string;
  stock: number;
  time: string;
}

export interface InventoryStatement {
  id: string;
  type: string;
  quantity: number;
  description: string;
  inventory: Inventory;
  student: Student;
  time: string;
}

export interface Request {
  id: string;
  category_id: string;
  batch_id?: string;
  batch?: Batch;
  category?: Category | null;
  description: string;
  status: RequestStatus;
  ticket_id: string;
  admin_note?: string;
  student?: Student;
  created_at: string;
  updated_at: string;
}

export interface SKUItem {
  id: string;
  tracking_id?: string;
  sku_code?: string;
  dispatch_date?: string;
  delivery_partner?: string;
  quantity: number;
  inventory?: Inventory;
  payment?: Payment;
  time?: string;
  status?: Fulfilment;
}

export interface FulfilmentList {
  id?: string;
  sku_code?: string;
  quantity: number;
  status?: Fulfilment;
  fulfilled: string;
  unfulfilled: string;
  inventory_id: string;
  inventory_name: string;
}

export enum Fulfilment {
  UNFULFILLED = "UNFULFILLED",
  FULFILLED = "FULFILLED",
}

export type FulfilmentType = "UNFULFILLED" | "FULFILLED";

export enum RequestStatus {
  OPEN = "OPEN",
  PROCESSING = "PROCESSING",
  RESOLVED = "RESOLVED",
  CLOSED = "CLOSED",
}

export interface TableHeaderType {
  name: any;
  value: string;
  default?: string;
  width?: string | number;
  className?: string | undefined;
  sort?: boolean;
  disableRowClick?: boolean;
  // eslint-disable-next-line no-unused-vars
  render?: (e: any, index: number) => any;
}

export interface TableHeaderWithType<T> {
  name: any;
  value: string;
  default?: string;
  width?: string | number;
  className?: string | undefined;
  sort?: boolean;
  disableRowClick?: boolean;
  // eslint-disable-next-line no-unused-vars
  render?: (e: T, index: number) => any;
}

export interface UserState {
  email: string;
  loggedIn: boolean;
  type: string;
  name: string;
  token: string;
  active: boolean;
}

export interface RequestState {
  entries: Request[];
  total: number;
  counts?: {
    [key: string]: number;
  };
}

export interface StudentResponse {
  student: Student;
}

export interface CategoryResponse {
  entries: Category[];
  total: number;
}

export interface IGetCategory {
  type: string;
}

export interface CreateCategoryDto {
  name: string;
  type: string;
  description: string;
}

export interface IGetCategory {
  type: string;
}

export interface AuthState {
  user: Student;
  token: string;
  loggedIn: boolean;
}

export interface Announcement {
  id: string;
  title: string;
  description: string;
  expired_at: string;
  status: string;
  admin_id: string;
  created_at: string;
  updated_at: string;
  batches: Batch[];
  seen: boolean;
}

export interface AnnouncementBatch {
  id: string;
  created_at: string;
  updated_at: string;
  seen: boolean;
  announcement: Announcement;
  batch: Batch;
  announcement_id: string;
  batch_id: string;
}

export interface GetAnnouncementResponse {
  entries: Announcement[];
  total: number;
  unseen: number;
}

export interface GetAnnouncementDto {
  skip: number;
  sort: string;
  take: number;
  orderby: string;
  member_id: string;
  batch_id: string;
}

/* eslint-disable no-unused-vars */
export enum ContentCategory {
  STUDY_MATERIAL = "STUDY_MATERIAL",
}

export enum ContentType {
  FOLDER = "FOLDER",
  FILE = "FILE",
}

export enum ContentFileType {
  VIDEO = "VIDEO",
  DOCUMENT = "DOCUMENT",
}

export enum PerformanceType {
  ONLINE = "ONLINE",
  OFFLINE = "OFFLINE",
}

/* eslint-enable no-unused-vars */

export interface Content {
  id: string;
  title: string;
  type: ContentType;
  filetype?: ContentFileType;
  url?: string;
  thumbnail?: string;
  category: string;
  parent_id?: string;
  batch_id?: string;
}

export interface ContentsResponse {
  entries: Content[];
  total: number;
}

export interface BatchAttendance {
  id: string;
  student_id: string;
  student: Student;
  class_id: string;
  present: boolean;
  created_at: string;
  updated_at: string;
}

export interface BatchClass {
  id: string;
  name: string;
  date: string;
  time: string;
  meet_link: string;
  attendance?: BatchAttendance;
  batch_id: string;
  batch: Batch;
  admin_id: string;
  admin: Member;
  created_at: string;
  updated_at: string;
  status: ClassStatus;
}

export interface BatchClassesResponse {
  entries: BatchClass[];
  total: number;
  present: number;
  absent: number;
  not_applicable: number;
}

export interface PerformanceResponse {
  entries: Performance[];
  total: number;
  present: number;
  absent: number;
  not_applicable: number;
}

export interface StudentMark {
  id: string;
  student_id: string;
  student: Student;
  performance_id: string;
  mark: number;
  present: boolean;
  created_at: string;
  updated_at: string;
}

export interface Leaderboard extends StudentMark {
  rank: number;
}

export interface LeaderboardResponse {
  entries: Leaderboard[];
  batchStudents: number;
  batchRank: number;
  instituteRank: number;
  instituteStudents: number;
}

export interface Performance {
  id: string;
  name: string;
  duration: number;
  fullmark: number;
  start_date: string;
  start_time: string;
  end_date: string;
  end_time: string;
  note: string;
  mark: number;
  present: boolean;
  rank: number;
  batch_id: string;
  batch?: Batch;
  admin_id: string;
  admin?: Member;
  created_at: string;
  updated_at: string;
  students: number;
  type: PerformanceType;
}

/* eslint-disable no-unused-vars */
export enum ClassStatus {
  STARTED = "STARTED",
  ENDED = "ENDED",
  NOT_STARTED = "NOT_STARTED",
}

export enum CategoryType {
  STUDENT = "STUDENT",
  BATCH = "BATCH",
  REQUEST = "REQUEST",
  SUBJECT = "SUBJECT",
  CHAPTER = "CHAPTER",
  DIFFICULTY_LEVEL = "DIFFICULTY_LEVEL",
}

export enum TestFilterType {
  ALL = "ALL",
  ANSWERED = "ANSWERED",
  UNANSWERED = "UNANSWERED",
  ANSWERED_MARKED = "ANSWERED_MARKED",
  UNANSWERED_MARKED = "UNANSWERED_MARKED",
}
/* eslint-enable no-unused-vars */

export interface TestResponse {
  entries: Test[];
  total: number;
}

export interface Test {
  id: string;
  title: string;
  description: string;
  duration_minute: number;
  full_marks: number;
  correct_mark: number;
  incorrect_mark: number;
  questionCount: number;
  questionAssigned: number;
  status: string;
  tags: string[];
  created_at: string;
  updated_at: string;
  sections: Section[];
  batches?: string[];
  start_date?: string;
  end_date?: string;
  start_time?: string;
  end_time?: string;
  number_of_attempts: number;
  manual_stop?: boolean;
  test_attempted: StudentTest[];
}

export interface GetTestResponse {
  entries: Test[];
  total: number;
}

// Subject
export interface Subject {
  id: string;
  name: string;
  totalChapter?: number;
  totalQuestion?: number;
  description?: string;
  created_at?: string;
  updated_at?: string;
  chapters: Chapter[];
}

export interface Chapter {
  id: string;
  name: string;
  description?: string;
  subject_id: string;
  subject?: Subject;
  totalQuestion?: number;
  created_at?: string;
  updated_at?: string;
}

export interface Tag {
  id: string;
  name: string;
}

export interface Question {
  id: string;
  subject_id: string;
  subject?: Subject;
  chapter_id: string;
  chapter?: Chapter;
  difficulty_level: string;
  question: string;
  options: QuestionOption[];
  solution: string;
  tags: Tag[];
  created_at?: string;
  updated_at?: string;
}

export interface QuestionOption {
  id: string;
  answer: string;
  correct: boolean;
  position: number;
}

export interface Section {
  id: string;
  name: string;
  test_id: string;
  totalQuestion?: number;
  created_at?: string;
  updated_at?: string;
  questions: Question[];
  questionIds?: string[];
}

export interface StudentTestAnswer {
  id: string;
  created_at: string;
  updated_at: string;
  section_id: string;
  question_id: string;
  question: Question;
  answer_id?: string;
  answer?: QuestionOption;
  is_review: boolean;
  is_report: boolean;
}

export interface StudentTest {
  id: string;
  started_at: string;
  ended_at: string;
  created_at: string;
  updated_at: string;
  test_id: string;
  test: Test;
  student_id: string;
  answers: StudentTestAnswer[];
  batch_id: string;
  batch: Batch;
  correct: number;
  answered: number;
  unanswered: number;
  incorrect: number;
  totalQuestion: number;
  score: number;
  accuracyPercentage: number;
  testAttempted: number;
  batchRank: number;
  batchStudents: number;
  instituteRank: number;
  instituteStudents: number;
  active: boolean;
}
