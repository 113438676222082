import { ReactNode } from "react";

interface ICard {
  children?: ReactNode;
  className?: string;
  onClick?: Function;
}

export function Card({ className, children, onClick }: ICard) {
  return (
    <div
      onClick={() => {
        if (onClick) onClick();
      }}
      className={"bg-gray-200 rounded-lg shadow-lg p-6 " + className}
    >
      {children}
    </div>
  );
}
