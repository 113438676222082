import { Member, Student } from "./default.interface";

/* eslint-disable no-unused-vars */
export enum ActivityActionType {
  UPDATE = "UPDATE",
  READ = "READ",
  CREATE = "CREATE",
  DELETE = "DELETE",
  DOWNLOAD = "DOWNLOAD",
  UPLOAD = "UPLOAD",
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  REQUEST = "REQUEST",
}

export enum UserType {
  ADMIN = "admin",
  STUDENT = "student",
}

/* eslint-disable no-unused-vars */

export enum UserRole {
  SUPER_ADMIN = "SUPER_ADMIN",
  MANAGER = "MANAGER",
  TEACHER = "TEACHER",
  STUDENT = "STUDENT",
}

export enum AppModule {
  AUTH = "AUTH",
  BATCH = "BATCH",
  STUDENT = "STUDENT",
  PAYMENT = "PAYMENT",
  FULFILMENT = "FULFILMENT",
  REQUEST = "REQUEST",
  BIRTHDAY = "BIRTHDAY",
  INVENTORY = "INVENTORY",
  MEMBER = "MEMBER",
  CATEGORY = "CATEGORY",
}

/* eslint-enable no-unused-vars */
export interface ActivityLog {
  id: string;
  type: UserType;
  role: UserRole;
  module: AppModule;
  action: ActivityActionType;
  admin_id?: string;
  admin?: Member;
  student_id?: string;
  student?: Student;
  created_at: Date;
  updated_at: Date;
  description: string;
  ip_address: string;
  meta: string;
}

export interface GetActivityLogResponse {
  entries: ActivityLog[];
  total: number;
}
