import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { API_ENDPOINT } from "../../config";
import { BatchClassesResponse, GetBatchClassDto } from "../../interfaces";

export const attendanceApi = createApi({
  reducerPath: "attendanceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT + "/attendance",
    credentials: "include",
  }),
  endpoints: (build) => ({
    getAttendances: build.query<BatchClassesResponse, GetBatchClassDto>({
      query: (params) => ({
        url: "/student",
        method: "GET",
        params,
      }),
    }),
  }),
});

export const { useGetAttendancesQuery } = attendanceApi;
