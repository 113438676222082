import toast, { ToastOptions } from "react-hot-toast";
import axios, { AxiosError } from "axios";

export const toastError = (error: Error | AxiosError) => {
  if (axios.isAxiosError(error)) {
    const err: AxiosError = error;
    if (err.response && err.response.data.messages) {
      err.response.data.messages.map((e: string) => {
        return toast.error(e);
      });
    } else if (err.response && err.response.data.message) {
      toast.error(err.response.data.message);
    } else toast.error("Something went wrong");
  } else {
    toast.error("Something went wrong");
  }
};

export const toastMessage = (status: "error" | "success", data?: any, options?: ToastOptions) => {
  if (typeof data === "string") {
    toast[status](data, options);
  } else if (Array.isArray(data?.messages)) {
    data.messages.map((e: string) => {
      return toast[status](e, options);
    });
  } else if (data?.message) {
    toast[status](data.message, options);
  } else {
    toast[status]("Something went wrong", options);
  }
};
