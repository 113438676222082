import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { API_ENDPOINT } from "../../config";
import { Payment, PaymentSuccessDto, StudentResponse } from "../../interfaces";
import { toastMessage } from "../../utils/toastError";

export const studentApi = createApi({
  reducerPath: "studentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT + "/student",
    credentials: "include",
  }),
  endpoints: (build) => ({
    getStudent: build.query<StudentResponse, void>({
      query: () => ({
        url: "/dashboard",
        method: "GET",
      }),
    }),
    createPayment: build.mutation<{ order: any; payment: Payment; apiKey: string }, { payment_id: string }>({
      query: (body) => ({
        url: "/payment",
        method: "POST",
        body,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
      },
    }),
    updatePayment: build.mutation<Payment, PaymentSuccessDto>({
      query: (body) => ({
        url: "/payment",
        method: "PUT",
        body,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
      },
    }),
    getPayments: build.query<{ entries: Payment[]; total: number }, void>({
      query: (body) => ({
        url: "/payment/all",
        method: "GET",
        body,
      }),
      transformErrorResponse(baseQueryReturnValue) {
        toastMessage("error", baseQueryReturnValue.data);
      },
    }),
  }),
});

export const {
  // api hooks
  useGetStudentQuery,
  useCreatePaymentMutation,
  useUpdatePaymentMutation,
  useGetPaymentsQuery,
} = studentApi;
