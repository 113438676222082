import { RequestStatus } from "../interfaces";

export const getRequestStatus = (status: string) => {
  switch (status) {
    case RequestStatus.OPEN:
      return "Open";
    case RequestStatus.CLOSED:
      return "Closed";
    case RequestStatus.PROCESSING:
      return "Processing";
    case RequestStatus.RESOLVED:
      return "Resolved";
    default:
      return "";
  }
};

export function formatTextWithLink(description: string = "") {
  return description.replace(
    /(?:https?|http):\/\/[^\s]+/g,
    (url) => `<a href="${url}" target="_blank" class="text-cyan-500" rel="noreferrer" >${url}</a>`
  );
}
